
import BotSelect from '@/components/bot/BotSelect.vue';
import isEqual from 'fast-deep-equal/es6';
import { Bot, QAItem } from 'ignite360-core';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { UniqueQAItem } from '@/types';

@Component({
  name: 'qna-items-import-modal',
  components: { BotSelect },
})
export default class QnaItemsImportModal extends Vue {
  @Prop({ required: true, type: Boolean, default: false })
  readonly show!: boolean;

  @Prop({ required: true, type: Array })
  readonly qaItems!: UniqueQAItem[];

  selectedBotId: Bot['id'] = '';
  selectedItemSet = new Set<number>();

  get selectedBot(): Bot | undefined {
    return this.$store.state.bots.bots.find((bot: Bot) => bot.id === this.selectedBotId);
  }

  get qaItemsWithDifferences(): QAItem[] {
    if (!this.selectedBot) {
      return [];
    }
    return this.selectedBot.qaItems.filter(
      (importQaItem) =>
        !this.qaItems.some(
          (localQaItem) =>
            isEqual(importQaItem.answerOptions, localQaItem.answerOptions) &&
            isEqual(importQaItem.questionOptions, localQaItem.questionOptions),
        ),
    );
  }

  get tableData(): any[] {
    if (!this.selectedBot) {
      return [];
    }
    return this.qaItemsWithDifferences.map((qaItem, index) => ({
      key: index,
      question: qaItem.questionOptions?.[0] || '',
      answer: qaItem.answerOptions?.[0] || '',
    }));
  }

  get allChecked(): boolean {
    return this.selectedItemSet.size === this.qaItemsWithDifferences.length;
  }

  onScroll(event: WheelEvent) {
    (this.$refs.scrollContainer as HTMLElement).scrollBy({ left: event.deltaY });
  }

  toggleSelectedItem(key: number) {
    const newSet = new Set(this.selectedItemSet);
    if (newSet.has(key)) {
      newSet.delete(key);
    } else {
      newSet.add(key);
    }
    this.selectedItemSet = newSet;
  }

  toggleAllChecked() {
    this.selectedItemSet = this.allChecked
      ? new Set()
      : new Set(this.tableData.map((data) => data.key));
  }

  onSubmit() {
    if (!this.selectedBot) return;
    const selectedIndices = Array.from(this.selectedItemSet);
    const qaItems = selectedIndices
      .filter((index) => !!this.qaItemsWithDifferences[index])
      .map((index) => this.qaItemsWithDifferences[index]);
    this.$emit('submit', qaItems);
    this.selectedItemSet.clear();
  }

  @Watch('selectedBotId')
  onSelectedBotChange() {
    this.selectedItemSet = new Set();
  }
}
