
import { UniqueQAItem } from '@/types';
import { Component, Prop, Vue } from 'vue-property-decorator';

type OptionKey = 'questionOptions' | 'answerOptions';

@Component({
  name: 'qna-items-list-item',
})
export default class QnaItemsListItem extends Vue {
  @Prop({ required: true, type: Object })
  readonly qaItem!: UniqueQAItem;

  focusFirstQuestion() {
    this.focusQuestion(0);
  }

  private focusQuestion(index: number) {
    const questionInput = (this.$refs.questions as Vue[] | undefined)?.find((component) => {
      return component.$vnode.key === index;
    }) as Vue | undefined;
    if (questionInput) {
      (questionInput.$el as HTMLElement).focus();
    }
  }

  private focusAnswer(index: number) {
    const answerInput = (this.$refs.answers as Vue[] | undefined)?.find((component) => {
      return component.$vnode.key === index;
    }) as Vue | undefined;
    if (answerInput) {
      (answerInput.$el as HTMLElement).focus();
    }
  }

  async onAdd(key: OptionKey) {
    const options = this.qaItem[key].slice();
    options.push('');
    this.$emit('input', { ...this.qaItem, [key]: options });
    await this.$nextTick();
    this[key === 'answerOptions' ? 'focusAnswer' : 'focusQuestion'](options.length - 1);
  }

  onInput(key: OptionKey, index: number, value: string) {
    const options = this.qaItem[key].slice();
    options[index] = value;
    this.$emit('input', { ...this.qaItem, [key]: options });
  }

  onDelete(key: OptionKey, index: number) {
    const options = this.qaItem[key].slice();
    options.splice(index, 1);
    this.$emit('input', { ...this.qaItem, [key]: options });
  }
}
