
import QnaItemsImportModal from '@/components/qna/QnaItemsImportModal.vue';
import QnaItemsListItem from '@/components/qna/QnaItemsListItem.vue';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import { UniqueQAItem } from '@/types';
import isEqual from 'fast-deep-equal/es6';
import { QAItem } from 'ignite360-core';
import { mixins } from 'vue-class-component';
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'qna-items-list-card',
  components: { QnaItemsImportModal, QnaItemsListItem },
})
export default class QnaItemsListCard extends mixins(BotMixin) {
  showModal = false;

  isSubmitting = false;

  qaItems: UniqueQAItem[] = [];
  idCount = 0;

  get qaItemsWithoutId(): QAItem[] {
    return this.qaItems.map((qaItem) => {
      const copy = { ...qaItem };
      delete (copy as any).id;
      return copy;
    });
  }

  get itemsHaveChanged() {
    return !isEqual(this.qaItemsWithoutId, this.bot.qaItems);
  }

  async onSubmit() {
    this.isSubmitting = true;
    try {
      await this.$updateBot({
        id: this.bot.id,
        data: {
          qaItems: this.qaItemsWithoutId,
        },
      });
      this.$notify.success('Successfully updated Q&A-items');
    } catch (e) {
      this.$notify.error({
        title: 'Updating Q&A-items failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  async onImportFromBot(qaItems: QAItem[]) {
    this.showModal = false;
    this.qaItems.push(...qaItems.map((qaItem) => ({ ...qaItem, id: this.idCount++ })));
    await this.$nextTick();
    this.scrollToBottomOfList();
  }

  async addItem() {
    this.qaItems.push({
      id: this.idCount++,
      name: '',
      questionOptions: [''],
      answerOptions: [''],
    });

    await this.$nextTick();

    this.scrollToBottomOfList();
    ((this.$refs.qaItems as Vue[] | undefined)?.[
      this.qaItems.length - 1
    ] as any)?.focusFirstQuestion();
  }

  private scrollToBottomOfList() {
    const formCard = this.$refs.formCard as Vue | undefined;
    const appCard = formCard?.$refs?.card as Vue | undefined;
    const body = appCard?.$refs?.body as HTMLElement | undefined;
    if (body) {
      body.scrollTop = body.scrollHeight;
    }
  }

  @Watch('bot', { immediate: true, deep: true })
  reset() {
    this.idCount = 0;
    this.qaItems = this.bot.qaItems.map((item) => {
      return {
        ...item,
        id: this.idCount++,
      };
    });
  }
}
