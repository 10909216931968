
import QnaItemsListCard from '@/components/qna/QnaItemsListCard.vue';
import { ProjectViewMixin } from '@/mixins/ProjectViewMixin';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'project-content-qna',
  components: { QnaItemsListCard },
})
export default class ProjectContentQnA extends mixins(ProjectViewMixin) {}
